body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d1f20;
}

canvas {
  background-size: cover;
  background-position: center center;
}

#border {
  box-sizing: border-box;
  position: relative;
  background: rgb(231, 231, 231);
  background-image: -webkit-gradient(
    linear,
    right top,
    left bottom,
    from(#111),
    to(#000)
  );
  padding: 20px;
  box-shadow: -1px 1px var(--blur) 1px rgba(0, 0, 0, 0.1),
    -2px 2px var(--blur) 1px rgba(0, 0, 0, 0.09),
    -3px 3px var(--blur) 1px rgba(0, 0, 0, 0.08),
    -4px 4px var(--blur) 1px rgba(0, 0, 0, 0.07),
    -5px 5px var(--blur) 1px rgba(0, 0, 0, 0.06),
    -6px 6px var(--blur) 1px rgba(0, 0, 0, 0.05),
    -7px 7px var(--blur) 1px rgba(0, 0, 0, 0.04),
    -8px 8px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -9px 9px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -10px 10px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -11px 11px var(--blur) 1px rgba(0, 0, 0, 0.03),
    -12px 12px var(--blur) 1px rgba(0, 0, 0, 0.02),
    -13px 13px var(--blur) 1px rgba(0, 0, 0, 0.02),
    -14px 14px var(--blur) 1px rgba(0, 0, 0, 0.01),
    -15px 15px var(--blur) 1px rgba(0, 0, 0, 0.01),
    -16px 16px var(--blur) 1px rgba(0, 0, 0, 0.01);
}

#frame {
  left: 3%;
  top: 2.5%;
  box-shadow: inset -1px 1px 6px 1px rgba(41, 41, 41, 0.24);
  background: white;
  align-items: center;
  display: flex;
  padding: 40px;
  box-sizing: border-box;
}

#circle-crop {
  display: block;
  /* position: relative; */
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid white;
  margin: 0 auto;
}

#lines-circle {
  display: block;
  width: 450px;
  height: 450px;
  overflow: hidden;
  margin: -6%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center-cards {
  margin: 0 auto;
}

.lazy-load-image-background {
  display: block !important;
}


/* Gallery Styles */
.gallery-images {

  /* disables selection on images */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge*/
   user-select: none;          /* Non-prefixed version, currently 
                                  not supported by any browser */

/* disables drag on images */
   -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
   user-drag: none;
}

.gallery-images {
  transition: grid-row-start 300ms linear;
  transition: transform 300ms ease;
  transition: all 0.5s ease;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 0 16px #333;
}
.gallery-images:hover {
  transform: scale(1.025);
  box-shadow: 0 0 32px #333;
}

@-moz-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@-webkit-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@-o-keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
@keyframes zoomin {
  0% {
    max-width: 50%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 50%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 100%;
    transform: rotate(0deg);
  }
}
